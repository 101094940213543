
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "e3d96391-5d18-4619-821f-4936de3814b8"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
